import { Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { from, Observable, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private sidenav: MatSidenav;

  private vcr: ViewContainerRef;
  private panelPortal$ = new Subject<TemplatePortal<HTMLElement>>();

  get panelPortal(): Observable<TemplatePortal<HTMLElement>> {
    return from(this.panelPortal$);
  }

  setSidenav(sidenav: MatSidenav) {
    this.sidenav = sidenav;
  }

  open() {
    return this.sidenav.open();
  }

  close() {
    return this.sidenav.close();
  }

  toggle(): void {
    this.sidenav.toggle();
  }

  setViewContainerRef(vcr: ViewContainerRef): void {
    this.vcr = vcr;
  }

  setPanelPortal(portal: TemplatePortal): void {
    portal.context = { prefix: 'mobile' };
    this.panelPortal$.next(portal);
  }
}
